@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "../wordpress/style.module.scss";

$max-width: 800px;

.section {
  padding: 3rem 1rem;
  border-bottom: 1px solid $border-color;
}

.contentContainer {
  width: 100%;
  margin: 0 auto;

  @media (min-width: $max-width) {
    max-width: $max-width;
  }
}
