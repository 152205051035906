@import "../../theme.module";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.searchBarBackground {
  background: $primary-200;  /* fallback for old browsers */
  background: linear-gradient(7deg, $accent-200 -20%, $tertiary-200 25%, $primary-200 100%);
  border-bottom: 1px solid $border-color;

  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
  }
}

.searchBarContainer {
  font-size: 100%;
  padding: 1rem 0;

  @include media-breakpoint-up(md) {
    font-size: 150%;
    padding: 2rem 0;
  }
}

.searchLabel {
  display: block;
	margin-right: 0.25rem;
}

.resultsListParent{
  padding-top: 1.1rem !important;
}

.resultContainer {
	margin-top: 1rem;
}

.tableContainer {
	margin-top: 1rem;
}

.tableHeadCell {
	text-transform: capitalize;
}

.tableEmpty {
	padding: 1rem;
	text-align: center;
}

.detailList mark, em {
	background: yellow;
}

.detailContainer {
	margin-left: 1rem;
}

.evidenceFootnote {
	font-size: 0.65rem;
}

td > .detailContainer {
	margin-left: 0;
}

.detailLabel {
	text-transform: capitalize;
}

.missingTerms {
  color: $text-muted;

  .detailValue {
    text-decoration: line-through;
  }
}

.resultExplanation {
  white-space: pre;
  font-size: x-small;
}

.explanationButton {
  margin-left: 2em;
}

.explanationList {
  list-style-type: none;
}

.agrDownloadBtn {
}


.relatedDataLinks {
  margin-left: 1em;
}


.resultCatLabel {
	float: right;
}

.resultLinkLabel {
  display: inline;
}

.totalPending {
    width: 2rem;
    height: 1rem;
    background: $gray-200;
    display: inline-block;
    position: relative;
    top: 0.2rem;
}

a > span > .sprite {
	background-position-x: -2.25rem;
}

.categoryLabel {
  &::before {
    content: '\2B22';
    position: relative;
    bottom: 0.1rem;
    margin-right: 0.15rem;
  }
  &.go::before { color: $data-type-go; }
  &.gene::before { color: $data-type-gene; }
  &.disease::before { color: $data-type-disease; }
  &.model::before { color: $data-type-model; }
  &.allele::before { color: $data-type-allele; }
  &.dataset::before { color: $data-type-dataset; }
}

.resultSpeciesIcon {
  float: right;
  opacity: .4;
}

.clear {
  clear: both;
}
