$primary-100: #dbeaf3;
$primary-200: #b6d5e8;
$primary-300: #8fc0dc;
$primary-400: #64acd1;
$primary-500: #2598C5;
$primary-600: #2681a6;
$primary-700: #256a88;
$primary-800: #22546c;
$primary-900: #1e4050;

$accent-100: #ffe8dc;
$accent-200: #ffd1b9;
$accent-300: #fbba96;
$accent-400: #f5a475;
$accent-500: #ed8d54;
$accent-600: #e47632;
$accent-700: #d95f02;
$accent-800: #aa4c0b;
$accent-900: #7e3a0f;

$tertiary-100: #f0e8f6;
$tertiary-200: #e1d2ec;
$tertiary-300: #d2bce3;
$tertiary-400: #c3a6da;
$tertiary-500: #b491d0;
$tertiary-600: #a47cc7;
$tertiary-700: #9467bd;
$tertiary-800: #755294;
$tertiary-900: #573e6d;

$primary: $primary-500;
$secondary: #7f7f7f;
$link-color: $primary-600;
$loader-color: $primary-800;

$data-type-gene: $primary-700;
$data-type-disease: $accent-700;
$data-type-go: $tertiary-700;
$data-type-model: #bcbd22;
$data-type-allele: #8c564b;
$data-type-dataset: #f7b6d2;
/*
Category colors (from SGD) that aren't used yet
.reference{background:#ff7f0e}
.biological_process{background:#2ca02c}
.molecular_function{background:#8c564b}
.phenotype{background:#e377c2}
.resource{background:#17becf}
.contig{background:#7f7f7f}
.download{background:#d62728}
.colleague{background:#ffbb78}
.reserved_name{background:#aec7e8}
.chemical{background:#ff9896}
.complex{background:#E6AB03}
 */

$boolean-true-green: #dff0d8;

$font-family-sans-serif: Lato, Helvetica, sans-serif;
$font-size-base: 0.9rem;

$popover-max-width: 400px;

$navbar-dark-color: rgba(white, 0.8);
$navbar-dark-hover-color: rgba(white, 0.95);

// ribbon overrides
$ribbon-species-icon: './components/speciesIcon/speciesSprite.png';
$ribbon-table-header-color: black;
$ribbon-table-header-bgcolor: white;
$ribbon-table-header-border-radius: 0px;
$ribbon-table-headline-font-size: 1.5rem;
$ribbon-table-filter-bgcolor: white;
$ribbon-table-filter-icon-color: #1849b4;
$ribbon-strip-tile-tile-width: 15px;
$ribbon-table-evidence-hover-color: rgba(75, 124, 231, 0.4);
$ribbon-strip-tile-border-radius: 0px;
