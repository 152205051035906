@import "../../../theme.module";

.done {
  height: 0 !important;
}

.pending {
  transition-duration: 5s;
  width: 85% !important;
}

.finishing {
  transition-duration: 250ms;
  width: 100% !important;
}

.loader {
  background: $loader-color;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  z-index: 9999;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}
