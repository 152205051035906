$bullet-width: 0.5rem;

.collapsibleList {
  list-style: none;
  padding: 0;
  margin: 0;

  // doing it this way instead of with list-style gives more control over
  // which character is used and the amount of spacing
  &.bulleted {
    padding-left: $bullet-width;

    li::before {
      content: '\2D'; // a hyphen
      display: inline-block;
      width: $bullet-width;
      margin-left: -1 * $bullet-width;
    }
  }
}

.toggleLink {
  padding: 0;
  .toggleIcon {
    transition: transform 200ms;
  }}

