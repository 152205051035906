@import "../../theme.module";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap');

$primaryColor__WB: #85A6BA;
$primaryColor__SGD: #696599;
$primaryColor__ZFIN: #018080;
$primaryColor__FB: #003568;
$primaryColor__GO: #264370;
$primaryColor__RGD: #699fc8;
$primaryColor__MGD: #dfeffe;
$secondaryColor__MGD: #143055;
$primaryColor__XB: #7d8e7b;

.modFooterText {
  vertical-align: middle;
  padding-left: .5rem;
}

.modFooterLabel {
  vertical-align: middle;
  padding-left: .5rem;
  font-size: 1.2rem;
}

.modFooter {
  bottom: 0px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  color: white;
}

.modFooterContainer {
  padding-left: 2.3rem;
}

.modFooter__DEFAULT {
  background-color: #2598c5;
}

.modFooter__WB, .titleBar__WB {
  background-color: $primaryColor__WB;
}

.modFooter__SGD, .titleBar__SGD {
  background-color: $primaryColor__SGD;
}

.modFooter__ZFIN, .titleBar__ZFIN {
  background-color: $primaryColor__ZFIN;
}

.modFooter__FB, .titleBar__FB {
  background-color: $primaryColor__FB;
}

.modFooter__GO, .titleBar__GO {
  background-color: $primaryColor__GO;
}

.modFooter__RGD, .titleBar__RGD {
  background-color: $primaryColor__RGD;
}

.modFooter__MGD, .titleBar__MGD {
  background-color: $primaryColor__MGD;
  color: $secondaryColor__MGD !important;
}

.modFooter__XB, .titleBar__XB {
  background-color: $primaryColor__XB;
}

.modFooter__WB a, .modFooter__FB a, .modFooter__SGD a, .modFooter__ZFIN a, .modFooter__GO a, .modFooter__XB a, .modFooter__RGD a {
  color: white;
}

.modFooter__MGD a {
  color: $secondaryColor__MGD !important;
}

.titleBar {
  color: white;
  bottom: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.titleBarContainer {
  padding-left: 2.3rem;
}

.titleBar__DEFAULT {
  background-color: #2598c5;
}

.titleBarText {
  vertical-align: middle;
  padding-left: .5rem;
  font-size: 2.25rem;
}

.titleBarEmptyRow {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 160px;
}

.noPadding {
  padding: 0;
}

.sectionTitle {
  color: #2598c5;
  padding-bottom: .75rem;
  font-size: 1.8rem;
}

.externalNews{
  color: #2598c5;
  padding-bottom: .75rem;
  font-size: 1.25rem;
}

.section {
  padding: 3rem 3rem 2rem 3rem;
  line-height: 1.7;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
//   font-size: 1rem;
//   font-family: "Open Sans", sans-serif;
}

.buttonVisitMod:hover {
  opacity: .8;
}

.buttonVisitMod {
  margin-top: 1.4rem;
  color: white;
  width: 100%;
  font-size: 1.5rem;
  border: 0;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 5px;
}

.resourceDiv p {
  margin-bottom: .7rem;
}

.h4extra {
  padding-top: 1rem;
}

.moreNews {
  padding-top: .8rem;
  padding-bottom: 1rem;
}

.containerExtra {
  margin: 1rem .25rem 0rem .25rem;
  padding-bottom: 1rem;
}

.section__SGD {
  border-color: $primaryColor__SGD;
}

.section__ZFIN {
  border-color: $primaryColor__ZFIN;
}

.section__FB {
  border-color: $primaryColor__FB;
}

.section__GO {
  border-color: $primaryColor__GO;
}

.section__RGD {
  border-color: $primaryColor__RGD;
}

.section__MGD {
  border-color: $primaryColor__MGD;
}

.section__WB{
  border-color: $primaryColor__WB;
}

.section__XB{
  border-color: $primaryColor__XB;
}

.banner {
//   background-size: cover;
//   background-position: center center;
}

.banner__DEFAULT {
  background-image: url('../../assets/images/banner_dnahelix.jpg');
}

.banner__WB {
  background-image: url('assets/topBannerWB.png');
  background-size: auto;
  background-position-x: center;
}

.banner__SGD {
  background-image: url('assets/topBannerSGD.png');
  background-size: auto;
  background-position-x: center;
}

.banner__ZFIN {
  background-image: url('assets/topBannerZFIN.png');
  background-size: cover;
  background-position: center center;
}

.banner__MGD {
  background-image: url('assets/topBannerMGD.png');
  background-size: auto;
  background-position-x: center;
}

.banner__RGD {
  background-image: url('assets/topBannerRGD.png');
  background-size: auto;
  background-position-x: center;
}

.banner__FB {
  background-image: url('assets/topBannerFB.png');
  background-size: cover;
  background-position: center center;
}

.banner__GO {
  background-image: url('assets/topBannerGOC.png');
  background-size: cover;
  background-position: center center;
}

.banner__XB {
  background-image: url('assets/topBannerXB.png');
  background-size: auto;
  background-position-x: center;
}

:global(.sharedaddy) {
  display: none;
}
