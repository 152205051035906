@import "../theme.module";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$max-width: 800px;

.section {
  padding: 3rem 1rem;
  border-bottom: 1px solid $border-color;
}

.contentContainer {
  width: 100%;
  margin: 0 auto;

  @media (min-width: $max-width) {
    max-width: $max-width;
  }
}

.member {
  width: 12.5%;
  min-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0.25rem;
  text-align: center;

  img {
    max-width: 100%;
    margin-bottom: 0.5rem;
  }
}
