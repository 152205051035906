@import "../theme.module";
@import "../mixins.module";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.externalLink {
  padding-left: 4px;
  font-size: 75%;
}

.loadingText {
  width: 100%;
  height: 1rem;
  background: $gray-400;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.loadingHeader {
  height: 2rem;
}

.subsection {
  margin-bottom: 4rem;
  clear: left;
  position: relative;

  h3 {
    border-bottom: 1px solid $border-color;
    padding-bottom: 8px;
    margin-bottom: 1rem;
  }

  h3, h4, h5 {
    position: relative;
  }

  .target {
    display: block;
    visibility: hidden;
    position: absolute;
    top: -1rem;
    bottom: 0;
    width: 100%;
  }

  @media (max-width: 768px) {
    .target {
      top: -125px;
      bottom: 125px;
    }
  }
}

.fadeInAndOut {
  opacity: 1;
  animation: fade 1s ease-in;
}

@keyframes fade {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

.commaSeparatedList {
  display: inline;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline;
  }

  li:after {
    content: ", ";
  }

  li:last-of-type:after {
    content: "";
  }
}

$shadow-color: rgba(black, 0.25);
$shadow-width: 20px;
.shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: $shadow-width;
  z-index: 2;
  pointer-events: none;
  transition: opacity 250ms ease-out;

  &.left {
    left: 0;
    background: radial-gradient(ellipse $shadow-width 60% at left, $shadow-color, transparent)
  }

  &.right {
    right: 0;
    background: radial-gradient(ellipse $shadow-width 60% at right, $shadow-color, transparent)
  }
}

.hScrollOuter {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  height: 100%;
  -webkit-overflow-scrolling: touch;

  @include webkit-scrollbar;
}

.ribbonSubjectLabel {
  font-size: 0.9rem;
  margin-right: 0.25rem;
}
