@mixin webkit-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: block;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
}
