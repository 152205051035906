@import "../../theme.module";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.postContainer {
  border-bottom: 1px solid $border-color;
  margin: 1rem 0;
  padding-bottom: 1rem;
}

.postContainer:last-of-type {
  border-bottom: none;
}

.postMenuContainer, .homeMenuContainer,
.aboutMenuContainer, .workingGroupsMenuContainer,
.contactMenuContainer, .helpMenuContainer, .citeMenuContainer {
  background-size: cover;
  background-color: $gray-900;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 1.3em;
}

.postMenuContainer {
  background-image: url('../../assets/images/banner_bacteria.jpg');
}

.aboutMenuContainer {
  background-image: url('../../assets/images/banner_dnahelix.jpg');
}

.contactMenuContainer {
  background-image: url('../../assets/images/banner_virus.jpg');
}

.workingGroupsMenuContainer {
  background-image: url('../../assets/images/banner_working_groups.jpg');
}

.helpMenuContainer {
  background-image: url('../../assets/images/banner_cells.jpg');
}

.citeMenuContainer {
  background-image: url('../../assets/images/banner_virus.jpg');
}

.secondaryNavEmptyRow, .postMenuContainer.secondaryNav {
  padding: 0;
  margin: 0;
  width: 100%;
}

.secondaryNavEmptyRow {
  height: 160px;
}

.secondaryNav {
  color: $primary;
  background-color: $gray-900;
  bottom: 0;
}

.floatLeft {
  background: $gray-800;
  padding: 0;
  margin-left: 0;
  margin-right: 1.2em;
  margin-bottom: 0.9em;
}
