@import "../../theme.module";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: white;
  opacity: 0;
  transition: opacity 0ms;
  pointer-events: none;
  font-size: 2em;
  color: $gray-600;

  &.loading {
    opacity: 0.7;
    transition: opacity 300ms ease-in;
  }
}

.filterToggle {
  cursor: pointer;
  margin-left: 5px;
  color: $primary;

  &.active {
    color: white;
    background: $primary;
    border-radius: 3px;
  }
}

.breakWords {
  overflow-wrap: break-word;
}

.tablePopup {
  max-height: 500px;
  overflow-y: scroll;

  .tablePopupInner {
    width: 900px;
  }
}

.helpIconWrapper {
  margin-left: 5px;
}

.relatedNotes {
  min-width: 300px;
}

.associationCell {
  min-width: 150px;
}